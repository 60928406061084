import React from 'react';
import {graphql} from 'gatsby';

import Layout from '@/components/Layout/Layout';
import {ICommonPageProps} from '@/core/dtos/ICommonPageProps';

const FourOhFourPage = ({location}: ICommonPageProps) => {
  return (
    <Layout lang='en' location={location}>
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist.</p>
    </Layout>
  );
};

export default FourOhFourPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
